/* ================================
=Variables
================================ */

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 992px) {
  // Heads
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  // Heads
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 18px;
  }
}

/* ================================
=Default
================================ */

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 992px) {
  section {
    padding: 60px 0;
  }
}

@media screen and (max-width: 768px) {
  section {
    padding: 30px 0;
  }

  p {
    font-size: 15px;
  }

  .page {
    ul {
      padding-left: 30px;

      li {
        font-size: 15px;
      }
    }
  }
}

/* ================================
=Header
================================ */

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 768px) {

}

/* ================================
=Body
================================ */

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 992px) {
  section.intro {
    height:400px;

    &.page {
      height: 200px;

      .inner {
        height: 200px;

        .inner-content {
          float: left;
        }
      }
    }

    .inner {
      height: 400px;

      .inner-content {
        left: 0;

        h1 {
          font-size: 42px;
        }

        p {
          font-size: 20px;
        }

        a.hvr-sweep-to-right {
          margin-top: 10px;
        }
      }
    }
  }

}

@media screen and (max-width: 768px) {

  /* ----- =Intro ------ */
  section.intro {
    height:300px;

    &.page {
      height: 150px;

      .inner {
        height: 150px;

        .inner-content {
          float: left;

          h1 {
            font-size: 32px;
          }
        }
      }
    }

    .inner {
      height: 300px;

      .inner-content {
        left: 0;

        h1 {
          font-size: 32px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }

  /* ----- =News sidebar ------ */

  .page {
    .news-sidebar {
      margin-top: 20px;
    }
  }

  /* ----- =Contact sidebar ------ */

  .page {
    .contact-sidebar {
      margin-top: 20px;
    }
  }

  /* ----- =Breadcrums ------ */

  section.breadcrumbs {
    p.breadcrumb {
      font-size: 15px;
    }
  }
}

/* ================================
=Footer
================================ */

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 768px) {

  section.intro.page {
    .icon-overlay {
      display: none;
    }
  }

}
