/*
Theme Name: Refresh Media
Theme URI: http://www.refresh-media.nl
Author: Refresh Media
Author URI: http://www.refresh-media.nl
Description: Thema ontwikkeld voor Refresh Media.
Version: 1.7.2.
*/

@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700');
@import '../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap';
@import "../../node_modules/hover.css/css/hover";

@import "_variables";
@import "_default";
@import "_menu-one-site"; /* Include menu one, two or shop. Also change the include in header.php. */
@import "_slideshow";
@import "_featured";
@import "_custom-post-type";
@import "_feature-image";
@import "_highlight";
@import "_accordion";
@import "_forms";

@import "../../node_modules/font-awesome/scss/font-awesome";

/* ================================
=Header
================================ */

/* ================================
=Body
================================ */

/* ----- =Intro ------ */

section.intro {
  background-color: transparent;
  background-position: center;
  background-size: cover;
  transform: translateX(-180deg);
  position: relative;
  height:800px;
  padding: 0;
  -webkit-backface-visibility: hidden;

  &.page {
    height: 350px;
    text-align: center;

    .picture-overlay {
      @include overlay;
      background-color: rgba(0,147,169,0.7);
    }

    .icon-overlay {
      background: url('assets/images/bg-icon.png') no-repeat;
      background-size: cover;
      width: 223px;
      height: 240px;
      position: absolute;
      z-index: 10;
      right: 0;
      top: 60px;
    }

    .inner {
      height: 350px;

      .inner-content {
        left: 0;

        h1 {
          font-size: 50px;
          text-transform: capitalize;

          span {
            text-transform: none;
          }
        }
      }
    }
  }

  .picture-overlay {
    @include overlay;
  }

  .icon-overlay {
    background: url('assets/images/bg-icon.png') no-repeat;
    background-size: cover;
    width: 250px;
    height: 250px;
    position: absolute;
    z-index: 10;
    right: 0;
    top: 100px;
  }

  .inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    height: 800px;
    position: relative;
    z-index: 1;
    text-align: center;

    .inner-content {
      padding: 15px;
      text-align: center;

      h1 {
        color: #fff;
        margin: 0;
        padding: 0;
        line-height: 100%;
        font-size: 65px;
        font-weight: 700;
        display: inline-block;
        position: relative;
      }

      p {
        font-size: 26px;
        color: $bg-color-grey;
        font-weight: 300;
        margin-top: 10px;
      }

      a.hvr-sweep-to-right {
        @include sweeptoright;

        &:hover {
          color: #fff;
        }
      }

      a.hvr-sweep-to-right:before{
        background-color: $primary-color-hover;
      }
    }
  }
}

/* ----- =Page ------ */

.page, .news {
  background-color: #fff;

  // News sidebar
  .news-sidebar {
    float: left;
    width: 100%;

    h3 {
      background: $primary-color;
      line-height: 40px;
      font-size: 22px;
      color: #fff;
      padding: 10px 25px;
      margin: 0;
    }

    .news-sidebar-content {
      background-color: #fff;
      padding: 20px 25px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
      float: left;
      width: 100%;
      border: 1px solid $bg-color-grey;

      a.news-sidebar-item {
        display: block;
        border: none;
        width: 100%;
        padding: 0 0 20px 0;
        margin-bottom: 20px;
        text-align: left;
        position: relative;
        float: left;
        border-bottom: 1px solid $bg-color-grey;

        &:last-child {
          margin-bottom: 0px;
          padding: 0;
          border-bottom: none;
        }

        .newsimage {
          width: 30%;
          height: 90px;
          margin-right: 15px;
          position: relative;
          overflow: hidden;
          display: block;
          float: left;

          &:before {
            @include overlay;
            background-color: rgba(0, 0, 0, 0.3);
          }

          img {
            height: 100%;
            max-width: 100%;
            object-fit: cover;
            position: relative;
            z-index: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            transition: ease 500ms;
            -webkit-backface-visibility: hidden;
          }
        }

        &:hover {
          text-decoration: none;

          .caption {
            h4 {
              color: $primary-color;
              text-decoration: none;
            }
          }

          img {
            transform: scale(1.2,1.2);
          }
        }

        .caption {
          background-color: #fff;
          padding: 0px;
          position: relative;
          z-index: 1;
          width: 50%;
          float: left;

          p {
            margin: 10px 0 0 0;
            font-size: 15px;
            color: $primary-color;
            font-weight: 600;
          }

          p.date {
            font-size: 14px;
            font-weight: 300;
            color: rgba(0,0,0,0.5);
            margin-top: 5px;
          }

          h4 {
            font-size: 16px;
            font-weight: 700;
            margin: 0px;
            color: $bg-color-black;
          }
        }
      }
    }
  }

  // Informatie sidebar

  .contact-sidebar {
    float: left;
    width: 100%;

    h3 {
      background: transparent $primary-color repeat scroll 0 0;
      line-height: 40px;
      font-size: 22px;
      color: #fff;
      padding: 10px 25px;
      margin: 0;
    }

    .contact-sidebar-content {
      background-color: #fff;
      padding: 10px 25px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
      float: left;
      width: 100%;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          line-height: 50px;
          border-bottom: 1px solid $bg-color-grey;

          &:before {
            display: none;
          }

          &:last-child {
            border: none;
          }

          i {
            margin-right: 10px;
            color: $primary-color;
            transition: $transition;
          }

          a:hover {
            text-decoration: none;
            i {
              margin-right: 5px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

.news {
  background-color: $bg-color-grey;
}

/* ----- =Breadcrums ------ */

  div.breadcrumb {
    margin: 5px 0 0 0;
    background-color: transparent;

    a {
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
      padding: 0 5px;

      &:hover {
        color: $primary-color;
        text-decoration: none;
      }
    }

    span {
      color: rgba(255,255,255,0.5);
      font-size: 16px;
      text-transform: uppercase;
      padding: 0 5px;
    }
  }

  /* ================================
  =Footer
  ================================ */

@import "_footer-col-three";
@import "_responsive";
