/* ================================
=Desktop
================================ */

html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 140px;
  font-size: 16px;
  font-family: $font-family;
}

section {
  padding: 90px 0;
}

.hvr-sweep-to-right {
  &:before {
    background: $primary-color-hover;
  }
}

a {
  color: $primary-color;
  font-weight: 600;

  &:hover, &:focus {
    color: $primary-color;
  }
}

.page {
  ul {
    margin-top: 20px;
    margin-bottom: 30px;
    line-height: 36px;
    list-style-type: none;

    li {
      &:before {
        content:"";
        background-color: $primary-color;
        display: inline-block;
        position: relative;
        top: -2px;
        margin-right: 15px;
        width: 7px;
        height: 7px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
      }
    }
  }
}

h1 {
  font-size: $h1-size;
  font-weight: 600;
}

h2 {
  font-size: $h2-size;
  color: $secondary-color;
  font-weight: 700;
  margin: 0;
  text-align: center;

  &:after {
    content: "";
    background-color: $secondary-color;
    width: 40px;
    height: 3px;
    position: relative;
    left: 50%;
    display: block;
    margin: 10px 0 30px -20px;
  }
}

h3 {
  font-size: $h3-size;
  font-weight: 600;
  color: $primary-color;
  margin: 0 0 20px 0;
}

h4 {
  font-size: $h4-size;
  color: $primary-color;
  font-weight: 600;
  margin: 30px 0 10px 0;
}

p {
  color: $paragraph-color;
  line-height: 28px;
  font-weight: 400;
  margin: 10px 0 30px 0;

  img {
    width: 100%;
    object-fit: contain;
    object-position: left;
  }
}

img {
  vertical-align: middle;
  max-width: 100%;
  -webkit-backface-visibility: hidden;
}

.center {
  text-align: center;
}

// Buttons

.btn-primary, .btn-ghost {
  color: #fff;
  background: rgba(0, 0, 0, 0) $primary-color repeat scroll 0 0;
  border-color: transparent;
  border-radius: 0px;
  text-transform: uppercase;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 12px;
  border: transparent;
  font-weight: 600;
  border-radius: 30px;
  overflow: hidden;
  font-weight: 500;

  &:hover, &:focus, &:link, a:active {
    background-color: $bg-color-grey;
    border-radius: 30px;
  }
}

.btn-ghost, .btn-ghost:link {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  margin-top: 20px;
  padding: 17px 30px;
  font-weight: 600;
  transition: $transition;

  &:hover, &:focus, a:active {
    background-color: transparent;
    color: $black-color;
    font-weight: 600;
  }

  &:hover {
    color: $black-color;
    background-color: #fff;
    border: 2px solid #fff;
    font-weight: 600;
  }
}

// Overlay
@mixin overlay {
  content: "";
  position: absolute;
  display: block;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

@mixin sweeptoright {
  margin-top: 30px;
  padding: 20px 35px;
  background-color: $bg-color-grey;
  color: $primary-color;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  -webkit-transition: ease 0.2s;
  transition: ease 0.2s;
  font-size: 14px;
  box-shadow: 0 4px 7px 0 rgbagulp(0, 0, 0, 0.10), 0 3px 12px 0 rgba(0, 0, 0, 0.08);
  background: rgba(0, 0, 0, 0) $bg-color-grey repeat scroll 0 0;
}

// Scroll animation

.scroll-left {
  position: relative;
  left: -500px;
  opacity: 0;
  transition: ease 1s;

  &.visible {
    opacity: 1;
    position: relative;
    left: 0;
  }
}

.scroll-fade {
  opacity: 0;
  transition: ease 1s;

  &.visible {
    opacity: 1;
  }
}

.parallax-mirror {
  z-index: 0!important;
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {


} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  body {
    /* Margin bottom by footer height */
    margin-bottom: 0px;
  }

}
