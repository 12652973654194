/* ================================
=Desktop
================================ */

.wpcf7-form {
  margin-top: -5px;
  .btn-primary {
    padding: 20px 30px;
    background-color: $primary-color;

    &:hover {
      background-color: $primary-color-hover;
    }
  }
}

.form-control {
  height: 60px;
  width: 95%;
  background-color: $bg-color-grey;
  color: $primary-color;
  margin-top: 5px;
  border-radius: 0px;
  padding: 20px 25px;
  box-shadow: none;
  border: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);

  &::placeholder {
    color: $placeholder-color;
  }

  &:focus {
    border-color: $primary-color;
    box-shadow: none;
  }
}

textarea.form-control {
    height: auto;
    min-height: 200px;
    padding: 20px 20px;
}

/* ------ =Validation ------ */

div.screen-reader-response {
  display: none;
}

.has-error .form-control {
    border-color: $primary-color;
}

.alert-warning, .alert-danger {
    border: none;
    border-radius: 0px;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #F46611;
    border-radius: 0px;
    margin-top: 10px;
    color: #F46611;
}

.alert-success {
  background-color: #fff;
  border-color: #3c763d;
  color: #3c763d;
  border-radius: 0px;
  width: 95%;
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  .form-control {
    width: 100%;
  }
}
