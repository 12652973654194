/* ================================
=Desktop
================================ */

.highlight {
  text-align: center;
  position: relative;
  background-size: cover;
  -webkit-backface-visibility: hidden;
  padding: 120px 0;

  .picture-overlay {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,147,169,0.7);
    opacity: 0.8;
  }

  .inner-content {
    position: relative;
    z-index: 2;

    .btn-ghost {
      float: right;
      margin: 0;
    }

    h3 {
      font-size: $h2-size;
      color: #fff;
      text-align: left;
    }
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

  .highlight {
    .inner-content {
      h3 {
        font-size: 24px;
      }
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  .highlight {
    padding: 50px 0;

    .inner {
      .inner-content {
        h3 {
          text-align: inherit;
          margin: 13px 0 0 0;
        }

        .btn-ghost {
          float: none;
          margin-top: 20px;
        }
      }
    }
  }

} /* End of view */
