// Font
$font-family: "Raleway", sans-serif;
$font-family-alt: "Raleway", sans-serif;
$font-size: 16px;
$font-size-small: 14px;
$font-weight: 300;

// Heads
$h1-size: 48px;
$h2-size: 42px;
$h3-size: 28px;
$h4-size: 20px;

// Colors
$primary-color: #0093a9; // Blue
$primary-color-hover: #007081;

$secondary-color: #007f54; // Green
$secondary-color-hover: #00603f;

$black-color: #444 ; // Grey/Black

$linear-gradient: linear-gradient(to right, #f46611 0%, #d55508 100%); // Orange gradient
$linear-gradient-black: linear-gradient(to right, #2a2a2a 0%, #191919 100%); // Black gradient

$paragraph-color: #474747;

$bg-color-grey: #def3f6;
$bg-color-black: #444;

$placeholder-color: #5eb3bf;

// Animations
$transition: ease all 0.4s;

// Font path
$fa-font-path: "assets/fonts/";
